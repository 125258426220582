<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="商品单位"
    add-btn="新增单位"
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="{ name: 1 }"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="商品单位">
        <a-input v-model="form.name" placeholder="输入商品单位查询" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="商品单位" prop="name">
        <a-input v-model="form.name" placeholder="请输入商品单位（最多10字）" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "CommodityUnit",
  data() {
    return {
      url: "/admin/asset-goods-unit",
      columns: [
        { title: "商品单位", dataIndex: "name" },
        { title: "更新时间", dataIndex: "update_time" },
      ],
      rules: {
        name: [{ required: true, message: "请输入商品单位", trigger: "blur" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
